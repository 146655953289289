import { defineNuxtRouteMiddleware, navigateTo } from '#imports'
import { useAuthStore } from '@/pinia/authStore'

export default defineNuxtRouteMiddleware(() => {
	const authStore = useAuthStore()
	const localePath = useLocalePath()

	if (authStore.isLoggedIn) {
		return navigateTo({ path: localePath('/discover/') })
	}
})
